import { useCallback, useRef, useState } from 'react'
import { useDisclosure } from '@chakra-ui/react'

export const useDialog = () => {
  const disclosure = useDisclosure()
  const { onOpen } = disclosure
  const [title, setTitle] = useState('')
  const [bodyMessage, setBodyMessage] = useState('')
  const cancelDialogRef = useRef<HTMLButtonElement>(null)

  const openDialogWithTitleAndMessage = useCallback(
    (title: string, bodyMessage: string) => {
      setTitle(title)
      setBodyMessage(bodyMessage)
      onOpen()
    },
    [title, bodyMessage],
  )

  return {
    errorDialogProps: {
      title,
      bodyMessage,
      leastDestructiveRef: cancelDialogRef,
      ...disclosure,
    },
    ...disclosure,
    openDialogWithTitleAndMessage,
  }
}
