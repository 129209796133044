import { useCallback, useEffect, useState } from 'react'
import { useAtom } from 'jotai'
import { RESET } from 'jotai/utils'
import { useRouter } from 'next/router'
import { destroyCookie, parseCookies, setCookie } from 'nookies'

import { getInquiryCalendarApi, postInquiryApi } from '@/api/inquiryApi'
import {
  inquiryDataAtom,
  inquiryOfJobLocalStorage,
  inquiryOfScoutLocalStorage,
  questionAboutIdOfLocalStorage,
} from '@/globalState/inquiry'
import { useSearchURL } from '@/hooks/useSearchURL'
import { sendClarityFiredEvent } from '@/modules/utils/gtm/clarityEventActions'
import { sendGAGenerateLead } from '@/modules/utils/gtm/gaEventActions'
import { CalendarInner } from '@/oas/talent/api'

export const useInquiry = () => {
  const router = useRouter()
  const { urlParamsLocal } = useSearchURL()

  const [calendar, setCalendar] = useState<CalendarInner[]>([])
  const [isLoading, setIsLoading] = useState(true)

  // global state
  const [inquiryData, setInquiryData] = useAtom(inquiryDataAtom) // postで使用

  // local storage
  const [inquiryOfJobToLocal, setInquiryOfJobToLocal] = useAtom(inquiryOfJobLocalStorage)
  const clearInquiryOfJobToLocal = useCallback(() => {
    // RESET を渡すと localStorage から削除される
    setInquiryOfJobToLocal(RESET)
  }, [setInquiryOfJobToLocal])

  const [inquiryOfScoutLocal, setInquiryOfScoutLocal] = useAtom(inquiryOfScoutLocalStorage)
  const clearInquiryOfScoutToLocal = useCallback(() => {
    // RESET を渡すと localStorage から削除される
    setInquiryOfScoutLocal(RESET)
  }, [setInquiryOfScoutLocal])

  // LINEログイン後着地ページがSSRのため、そこにjobIdを引き回す用にCookieにセットする関数
  const setInquiryOfJobIdToCookie = useCallback((id: string) => {
    setCookie(null, 'inquiry_of_job_id', id, {
      maxAge: 30 * 24 * 60 * 60, // 30日間有効
      path: '/',
    })
  }, [])
  const getInquiryOfJobIdToCookie = useCallback(() => {
    const cookies = parseCookies()
    return cookies.inquiry_of_job_id ?? null
  }, [])
  const clearInquiryOfJobIdToCookie = useCallback(() => {
    destroyCookie(null, 'inquiry_of_job_id', { path: '/' })
  }, [])

  const [questionAboutIdLocal, setQuestionAboutIdToLocal] = useAtom(questionAboutIdOfLocalStorage)
  const clearQuestionAboutId = useCallback(() => {
    // RESET を渡すと localStorage から削除される
    setQuestionAboutIdToLocal(RESET)
  }, [setQuestionAboutIdToLocal])

  useEffect(() => {
    if (!router.pathname.includes('/inquiry')) return
    // 画面表示時にカレンダー情報を取得
    getInquiryCalendarApi()
      .then((res) => setCalendar(res.data))
      .finally(() => setIsLoading(false))
  }, [])

  // スカウトの場合と求人検索応募の場合分けでGA4イベントを送信
  const sendGenerateLeadByCase = useCallback(() => {
    if (inquiryOfScoutLocal) {
      // スカウトの場合はリスト内表示位置や検索クエリなどがない
      sendGAGenerateLead(inquiryOfScoutLocal.scout.job.id, 'scout')
    } else if (inquiryOfJobToLocal) {
      // 求人検索の場合はリスト内表示位置や検索クエリなども送る
      sendGAGenerateLead(
        inquiryOfJobToLocal.job.id,
        'search',
        urlParamsLocal.job_list_order ? Number(urlParamsLocal.job_list_order[0]) : undefined,
        urlParamsLocal,
      )
    }
  }, [inquiryOfScoutLocal, inquiryOfJobToLocal])

  /**
   * お問い合わせ
   * 引数に入るのは、エラー時の処理
   * @param errorFunc
   * @returns
   */
  const inquiry = async (errorFunc: (isConflict: boolean) => void) => {
    if (!inquiryData) return
    setIsLoading(true)
    await postInquiryApi(inquiryData)
      .then((v) => {
        if (v.status === 201) {
          sendClarityFiredEvent('inquired')
          sendGenerateLeadByCase()
          clearInquiryOfJobToLocal()
          clearInquiryOfScoutToLocal()
          router.push('/inquiry/complete')
          return
        }
        if (v.status === 422 || v.status === 409) {
          // バリデーションエラーまたはコンフリクトの場合は、エラー処理を実行
          errorFunc(v.status === 409)
          return
        }
        // その他のエラーの場合は、500エラー画面に遷移
        router.push({
          pathname: '/_error',
          query: { statusCode: v.status },
        })
      })
      .finally(() => setIsLoading(false))
  }

  return {
    isLoading,
    calendar,
    inquiry,
    inquiryOfJobToLocal,
    setInquiryOfJobToLocal,
    inquiryOfScoutLocal,
    setInquiryOfScoutLocal,
    inquiryData,
    setInquiryData,
    setInquiryOfJobIdToCookie,
    getInquiryOfJobIdToCookie,
    clearInquiryOfJobIdToCookie,
    questionAboutIdLocal,
    setQuestionAboutIdToLocal,
    clearQuestionAboutId,
    clearInquiryOfJobToLocal,
    clearInquiryOfScoutToLocal,
  }
}
