import { useCallback, useMemo } from 'react'
import { useAtom } from 'jotai'
import { useRouter } from 'next/router'
import { ParsedUrlQuery } from 'querystring'

import { urlParamsAtom } from '@/globalState/jobSearchCondition'

// パスパラメータを解析する関数
const parsePathParams = (pathname: string): { [key: string]: string[] } => {
  const pathParams: { [key: string]: string[] } = {}
  // クエリパラメータの場合と形式を揃えつつ配列化
  const pathParts = pathname.split('/').filter((part) => part.includes('_'))
  pathParts.forEach((part) => {
    const [key, value] = part.split('_')
    if (key && value) {
      pathParams[key] = [value]
    }
  })
  return pathParams
}
// クエリパラメータを解析する関数
const parseQueryParams = (query: ParsedUrlQuery): { [key: string]: string[] } => {
  const queryParams: { [key: string]: string[] } = {}

  Object.entries(query).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      queryParams[key] = value
    } else {
      queryParams[key] = value ? [value] : []
    }
  })

  return queryParams
}
/**
 * 求人一覧画面の検索条件URLをハンドリングするためのカスタムフック
 */
export const useSearchURL = () => {
  const router = useRouter()
  const { pathname, query } = router
  const [urlParamsLocal, setUrlParamsLocal] = useAtom(urlParamsAtom)

  /**
   * 現在のURLの検索パラメータをオブジェクト化したものを返却する
   */
  const searchParamArray = useMemo(() => {
    const searchParams = {
      id: [],
      jobCategory: [],
      job_list_order: [],
      prefecture: [],
      workLocationPrefecture: [],
    }
    const pathParams = parsePathParams(pathname)
    const queryParams = parseQueryParams(query)
    Object.assign(searchParams, pathParams, queryParams)
    return searchParams
  }, [pathname, query])

  const searchParamsJson = useMemo(() => {
    return JSON.stringify(searchParamArray)
  }, [searchParamArray])

  /**
   * 現在のURLの検索パラメータオブジェクトをLocalStorageにセットする
   */
  const setCurrentUrlParamsLocal = useCallback(() => {
    setUrlParamsLocal(searchParamArray)
  }, [searchParamArray])

  return {
    searchParamArray,
    searchParamsJson,
    urlParamsLocal,
    setCurrentUrlParamsLocal,
  }
}
