import { Text, VStack } from '@chakra-ui/react'

import Logo from '@/modules/assets/images/logo.svg'
import { ChakraNextImage } from '@/modules/components/ChakraNextImage'

export const LogoAndTitleElm = ({ title }: { title?: string }) => {
  return (
    <VStack w="100%" spacing="16px" p="24px">
      <ChakraNextImage src={Logo.src} alt="logo" />
      {title && (
        <Text fontSize="16px" lineHeight="150%" fontWeight={700}>
          {title}
        </Text>
      )}
    </VStack>
  )
}
