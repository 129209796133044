import { useEffect, useState } from 'react'

interface UserAgentProps {
  userAgent: string
  isWebView: boolean
}

const checkWebView = (ua: string): boolean => {
  // Facebook
  if (ua.includes('FB')) {
    return true
  }

  // Instagram
  if (ua.includes('Instagram')) {
    return true
  }

  // LINE
  if (ua.includes('Line')) {
    return true
  }

  // TikTok
  if (ua.includes('trill')) {
    return true
  }

  // WebView
  if (ua.includes('wv')) {
    return true
  }
  return false
}

export const useUserAgent = (): UserAgentProps => {
  const [userAgent, setUserAgent] = useState<string>('')
  const [isWebView, setIsWebView] = useState(false)

  useEffect(() => {
    // クライアントサイドでUser-Agentを取得
    setUserAgent(navigator.userAgent)
    // WebViewかどうかの判定
    setIsWebView(checkWebView(navigator.userAgent))
  }, [])

  return { userAgent, isWebView }
}
