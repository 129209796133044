import { atom } from 'jotai'
import { atomWithStorage } from 'jotai/utils'

import { localStorageKey } from '@/const/localStorage'
import { InquiryRequest, Job, TalentScout } from '@/oas/talent/api'

/**
 * 問い合わせ時に使用する情報を保持するAtom（globalState）
 */
export const inquiryOfScoutLocalStorage = atomWithStorage<{
  scout: TalentScout
  isGoodFeedback?: boolean
} | null>(localStorageKey.INQUIRY_OF_SCOUT, null, undefined, { unstable_getOnInit: true })
inquiryOfScoutLocalStorage.debugLabel = 'inquiryOfScoutLocalStorage'

export const inquiryDataAtom = atom<InquiryRequest | null>(null)
inquiryDataAtom.debugLabel = 'inquiryData'

/**
 * 問い合わせ時に使用する情報を保持するAtom（localStorage）
 */
export const inquiryOfJobLocalStorage = atomWithStorage<{
  job: Job
  entryPrefectureMasterId: string | null
} | null>(localStorageKey.INQUIRY_OF_JOB, null, undefined, { unstable_getOnInit: true })
inquiryOfJobLocalStorage.debugLabel = 'inquiryOfJobLocalStorage'

export const jobIdOfLocalStorage = atomWithStorage<string | null>(
  localStorageKey.JOB_ID,
  null,
  undefined,
  { unstable_getOnInit: true },
)
jobIdOfLocalStorage.debugLabel = 'jobIdOfLocalStorage'

export const questionAboutIdOfLocalStorage = atomWithStorage<number | null>(
  localStorageKey.QUESTION_ABOUT_ID,
  null,
  undefined,
  { unstable_getOnInit: true },
)
questionAboutIdOfLocalStorage.debugLabel = 'questionAboutIdOfLocalStorage'
