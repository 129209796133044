import { axiosInstance } from '@/api/axiosInstance'
import { authCookieName } from '@/hooks/useAuth'
import { InquiryApi, InquiryRequest } from '@/oas/talent/api'

import { getTalentApiUrl } from './apiHostNameSwitcher'

const inquiryApi = new InquiryApi(undefined, getTalentApiUrl(), axiosInstance)

export const getInquiryCalendarApi = () => {
  return inquiryApi.fetchInquiryCalendar({
    withCredentials: true,
  })
}

export const postInquiryApi = (req: InquiryRequest) => {
  return inquiryApi.createInquiry(req, {
    withCredentials: true,
  })
}

export const fetchInquiryListApi = (authCookie?: string) => {
  return inquiryApi.fetchInquiriesList({
    withCredentials: true,
    headers: authCookie
      ? {
          Cookie: `${authCookieName}=${authCookie}`,
        }
      : {},
  })
}
