import { atom } from 'jotai'
import { atomWithStorage } from 'jotai/utils'

import { localStorageKey } from '@/const/localStorage'

export type JobSearchConditionSelected = {
  prefectures: string[]
  jobCategories: string[]
  particularConditions: string[]
}
export type SearchUrlParams = {
  id: string[]
  jobCategory: string[]
  job_list_order: string[]
  prefecture: string[]
  workLocationPrefecture: string[]
}

/**
 * 直近の求人検索条件の状態管理に用いられるAtom
 * ID列で管理する
 */
export const jobSearchConditionAtom = atom<JobSearchConditionSelected>({
  prefectures: [],
  jobCategories: [],
  particularConditions: [],
})
jobSearchConditionAtom.debugLabel = 'jobSearchCondition'

export const urlParamsAtom = atomWithStorage<SearchUrlParams>(
  localStorageKey.SEARCH_URL_PARAMS,
  { id: [], jobCategory: [], job_list_order: [], prefecture: [], workLocationPrefecture: [] },
  undefined,
  { unstable_getOnInit: true },
)
urlParamsAtom.debugLabel = 'urlParamsInLocalStorage'
